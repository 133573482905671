<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 班级学习 </template>
      <template #input>
        <a-select placeholder="请选择开班状态" allowClear v-model="classStatus">
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option :value="1">未开班</a-select-option>
          <a-select-option :value="2">开班中</a-select-option>
          <a-select-option :value="3">已结束</a-select-option>
        </a-select>
        <a-input v-model="keyword" placeholder="请输入关键字" />
        <a-range-picker
          format="YYYY-MM-DD"
          :placeholder="['开班时间', '结班时间']"
          @change="changTime"
        />
        <a-button type="primary" class="btn" :loading="loading" @click="search()">搜索</a-button>
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-tabs default-active-key="1" @change="callback">
        <a-tab-pane
          :key="item.courseId"
          :tab="item.courseName"
          :value="item.price"
          v-for="item in courseData"
        ></a-tab-pane>
      </a-tabs>

      <a-table
        class="table-template"
        :rowKey="(item) => item.id"
        :columns="columns"
        :loading="loading"
        :data-source="tableData"
        @change="onPage"
        :pagination="{
          total: total,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>

        <template slot="status" slot-scope="item">
          <div style="text-align: center">
            {{ item == 1 ? "未开班" : item == 2 ? "开班中" : "已结束" }}
          </div>
        </template>

        <template slot="operation" slot-scope="item, row">
          <div class="btn_router_link">
            <a @click="onStudentList(row.openTimeId)">查看班级学员</a>
            <!-- <span @click="getHistory(row.openTimeId)">历史成绩</span> -->
          </div>
        </template>
      </a-table>
    </div>

    <!-- 历史成绩 -->
    <a-modal
      v-model="achievementsShow"
      align="center"
      title="历史成绩"
      :footer="null"
    >
      <a-table
        class="table-template"
        style="margin: 0"
        :rowKey="(item) => item.id"
        :columns="columns2"
        :data-source="achievementsList"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "班级名称",
    align: "center",
    dataIndex: "className",
  },
  {
    title: "开班时间",
    align: "center",
    dataIndex: "startTime",
  },
  {
    title: "结班时间",
    align: "center",
    dataIndex: "endTime",
  },
  {
    title: "学员人数",
    align: "center",
    dataIndex: "classPeople",
  },
  {
    title: "班级状态",
    align: "center",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "operation" },
  },
];
const columns2 = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "考试场次日期",
    align: "center",
    dataIndex: "examDate",
  },
  {
    title: "考试成绩",
    align: "center",
    dataIndex: "examScore",
  },
  {
    title: "总成绩",
    align: "center",
    dataIndex: "totalScore",
  },
];
import HeaderBox from "@/components/HeaderBox.vue";

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      columns, // 表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      achievementsShow: false, // 历史成绩
      achievementsList: [],
      columns2, // 历史成绩表头
      courseData: [], //课程数据
      courseId: null, //课程id
      keyword: null, // 关键字
      classStatus: undefined, // 开班状态
      time: [],
    };
  },
  // 事件处理器
  methods: {
    // 时间选择
    changTime(e, r) {
      this.time = r;
    },
    // tab切换
    callback(e) {
      // console.log(e);
      this.courseId = e;
      this.pageNumber = 1; //页码
      this.getdataList();
    },
    // 跳班级学员详情
    onStudentList(e) {
      this.$router.push({
        path: "/admin/StudyMange/ClassStudents",
        query: { id: e, courseId: this.courseId },
      });
    },
    // 分页
    onPage(e) {
      // console.log(e);
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.selectedRowKeys = [];
      this.getdataList();
    },
    // 搜索
    search() {
      this.pageNumber = 1;
      // this.pageSize = 10;
      // this.selectedRowKeys = [];
      this.getdataList();
    },
    // 获取数据
    getdataList() {
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/course/manage/class-study",
        method: "get",
        params: {
          pageNum: this.pageNumber,
          pageSize: this.pageSize,
          courseId: this.courseId,
          keyword: this.keyword,
          classStatus: this.classStatus,
          startTime: this.time[0],
          endTime: this.time[1],
        },
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    // 获取课程
    getcourse() {
      this.$ajax({
        url: "/hxclass-management/course/study-manage",
        method: "get",
        params: {
          classType: 2,
        },
      }).then((res) => {
        // console.log(res);
        if (res.code == 200 && res.success) {
          this.courseData = res.data;
          this.courseId = res.data[0].courseId;
          this.getdataList();
        }
      });
    },
    // 获取历史成绩
    getHistory(e) {
      // console.log(e);
      this.achievementsShow = true;
      this.$ajax({
        url: "/hxclass-management/exam/examinee/study/record?userId=" + e,
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.achievementsList = res.data;
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getcourse();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}
.all_content_box {
  .line-item {
    margin-bottom: 20px;
    display: flex;
    .all_left_name {
      width: 90px;
      text-align: right;
      margin-right: 20px;
    }
  }
  .right {
    flex: 1;
    /deep/.ant-input {
      height: 40px;
    }
  }
  textarea.ant-input {
    flex: 1;
  }
}
</style>
